var MobileMenu = function () {

    this.run = function () {
        var btn = $('[data-hamburgers-btn]');
        var menu = $('[data-hamburgers-menu]');

        btn.on('click', function () {
            if (btn.hasClass('is-active')) {
                btn.removeClass('is-active');
                menu.removeClass('is-active');
            } else {
                btn.addClass('is-active');
                menu.addClass('is-active');
            }
        })

    };

};

var mobile_menu = new MobileMenu();

$(function () {
    mobile_menu.run();
});
