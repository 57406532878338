var Tab = function () {

    var init = function (parent) {
        first(parent);
        click(parent);
    };

    var click = function (parent) {
        $(parent).find('[data-tab-btn]').on('click', function (e) {
            e.preventDefault();
            show(parent, $(this).attr('data-tab-btn'));
        })
    };

    var first = function (parent) {
        var first = $(parent).attr('data-active-tab');
        setTimeout(function () {
            show(parent, first);
        }, 300)
    };

    var show = function (parent, id) {
        $(parent).find('[data-tab-box]').addClass('d-none');
        $(parent).find('[data-tab-btn]').removeClass('active');

        $(parent).find('[data-tab-box="' + id + '"]').removeClass('d-none');
        $(parent).find('[data-tab-btn="' + id + '"]').addClass('active');

        if ($(parent).find('.has-carousel').length > 0) {
            $('.has-carousel').slick('setPosition');
        }
    };

    this.run = function () {
        $.each($('[data-tab]'), function (key, value) {
            init(value)
        })
    };

};

var tab = new Tab();

$(function () {
    tab.run();
});
