var Script = function () {

    var plugins = function () {
        $(':input').inputmask();
        var resizableArea = $(".resizable.front");
        var arrowImage = $('section.home-content .promo-box .promo-item .promo-image .arrow-img');

        resizableArea.resizable({
            maxWidth: 478,
            resize: function (event, ui) {
                arrowImage.css({
                    left: ui.size.width
                })
            }
        });

        setTimeout(function () {
            resizableArea.animate(
                {width: '478px'},
                1200,
                function () {
                    resizableArea.animate(
                        {width: '50%'},
                        600,
                        function () {
                            arrowImage.css('visibility', 'visible');
                        }
                    );
                }
            );

        }, 1000);
    };

    var homeDetail = function () {
        $('.home-category-list .has-carousel').slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        })

        $('section.home-content .blog-list .has-carousel').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        })
    };

    var productDetail = function () {

        $('.product-gallery').slick({
            slidesToShow: 1,
            slidesToScroll: 1
        });

        $('.product-gallery-thumb').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            focusOnSelect: true,
            asNavFor: '.product-gallery'
        });

        /*$('.product-other .has-carousel').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        })*/

        /*$('.product-other .has-carousel').flickity({
            cellAlign: 'left',
            contain: true
        })*/

    };

    var colorSelector = function () {
        $('[data-color-selector]').on('change', function () {
            var colorCode = $(this).find(':selected').attr('data-color');
            var minMaxIndex = minMaxDataIndex($('.product-gallery img[data-color-code="' + colorCode + '"]'));

            if (minMaxIndex.min === null || parseInt(minMaxIndex.min) < 0) {
                $('.product-gallery').slick('slickGoTo', 0);
                $('.product-gallery-thumb').slick('slickGoTo', 0);
            } else {
                $('.product-gallery').slick('slickGoTo', parseInt(minMaxIndex.min));
                $('.product-gallery-thumb').slick('slickGoTo', parseInt(minMaxIndex.min));
            }

            $('[data-color-area]').css({
                'background-color': $(this).find(':selected').attr('data-color'),
                'border-color': $(this).find(':selected').attr('data-color')
            });
        })
    };

    var minMaxDataIndex = function (selector) {
        var min = null, max = null;
        $(selector).each(function () {
            var id = parseInt($(this).attr('data-index'), 10);
            if ((min === null) || (id < min)) {
                min = id;
            }
            if ((max === null) || (id > max)) {
                max = id;
            }
        });
        return {min: min, max: max};
    };

    var dropdownAutoCloseDisable = function () {
        $(document).on('click', '.dropdown-menu', function (e) {
            if (!$(e.target).attr('data-close')) {
                e.stopPropagation();
            }
        });
    };

    var showCartBox = function () {
        $('section.site-cart .show-btn').on('click', function () {
            $(this).parent().toggleClass('active');
        });
    };

    var modify = function () {
        if ($('.product-detail li.nav-item').length) {
            $('.product-detail li.nav-item a').first().trigger('click');
        }
    };

    var arrayRemove = function (arr, value) {

        return arr.filter(function (ele) {
            return ele != value;
        });

    };

    var homeCheckboxSelect = function () {
        var selected = [];
        $.each($('.promo-form .dropdown'), function (val) {
            selected['group-' + val] = new Array(0);
        });
        $('.promo-form .custom-control-input').on('change', function () {
            if ($(this).is(':checked')) {
                if ($(this).next().text().length > 0) {
                    selected['group-' + $(this).parents('.dropdown').parent().index()].push($(this).next().text());
                }
            } else {
                var index = selected['group-' + $(this).parents('.dropdown').parent().index()].indexOf($(this).next().text());

                if (index > -1) {
                    selected['group-' + $(this).parents('.dropdown').parent().index()].splice(index, 1);
                }
            }

            Object.keys(selected).forEach(function (group) {
                if (selected[group].length > 0) {
                    $('.' + group).find('.selected-item').html('');
                    selected[group].forEach(function (val) {
                        $('.' + group).find('.select-item').hide();
                        $('.' + group).find('.selected-item').append('<span>' + val + '</span>');
                    });
                } else {
                    $('.' + group).find('.selected-item').html('');
                    $('.' + group).find('.select-item').show();
                }
            });
        });
    };

    var searchPageForm = function () {
        $('form[name="form-search-page"] input').change(function () {
            $('form[name="form-search-page"]').submit();
        });
        $('form[name="form-search-page"] [data-button="category-checkbox"]').click(function () {
            var button = $(this);
            var active = button.hasClass('active');
            var value = button.attr('data-value');
            var checkbox = button.siblings('input[type="checkbox"][value="' + value + '"]');

            button.toggleClass('active');
            checkbox.prop('checked', !active).trigger('change');
        });
    };

    var promoFormShow = function () {
        if ($(window).width() >= 768) {
            return false;
        }
        var promo = $('.promo-form');
        promo.find('[type="submit"]').on('click', function (e) {
            if (!promo.hasClass('has-submit')) {
                e.preventDefault();
                promo.addClass('has-submit');
            } else {
                promo.find('form').submit();
            }
        })
    };

    this.scrollToDetail = function () {
        $('html, body').animate({
            scrollTop: $('#tab-property').offset().top - 120
        })
    }


    var basket = {
        products: [],
        open: false,
        doms: {
            productForm: $('[name="form-add-product"]'),
            basket: $('[data-name="basket"]'),
            basketProductCount: $('[data-name="basket-product-count"]'),
            basketProductContainer: $('[data-container="basket-products"]'),
            productModalProductContainer: $('[data-container="modal-basket-products"]'),
            productModal: $('#modal-product')
        },
        actions: {
            addProduct: function (id, name, imageSrc) {
                var colorOption = $('select[name="color"] option:selected', basket.doms.productForm).eq(0);

                if (colorOption.val() === '') {
                    alert('Lütfen Renk Seçin');
                } else {
                    var piece = parseInt($('input[name="piece"]', basket.doms.productForm).val());
                    var colorCode = colorOption.attr('data-color');
                    var index = null;
                    var basketProducts = basket.products.filter(function (basketProduct, i) {
                        if (basketProduct.id === id && basketProduct.color.code === colorCode) {
                            index = i;
                            return true;
                        }
                    });

                    if (basketProducts.length === 1) {
                        var basketProduct = basket.products.splice(index, 1)[0];
                        basketProduct.piece = basketProduct.piece + piece;
                        basket.products.push(basketProduct);
                    } else {
                        if (imageSrc === null) {
                            var minMaxIndex = minMaxDataIndex($('.product-gallery img[data-color-code="' + colorCode + '"]'));

                            if (minMaxIndex.min === null) {
                                imageSrc = $('.product-gallery img[data-index="0"]').attr('src');
                            } else {
                                imageSrc = $('.product-gallery img[data-color-code="' + colorCode + '"][data-index="' + minMaxIndex.min + '"]').attr('src');
                            }
                        }

                        var product = {
                            id: id,
                            image: imageSrc,
                            color: {
                                code: colorCode,
                                label: colorOption.text()
                            },
                            name: name,
                            piece: piece
                        };
                        basket.products.push(product);
                    }

                    Cookies.set('basket', basket.products);
                    basket.actions.openBasket();
                    basket.actions.updateBasketProductCount();
                }
            },
            removeProduct: function (index, from) {
                basket.products.splice(index, 1);
                Cookies.set('basket', basket.products);

                if (from === 'basket') {
                    basket.actions.updateBasketProductItems();
                } else if (from === 'modal') {
                    basket.actions.updateBasketProductItems();
                    basket.actions.updateModalBasketProductItems();
                }

                basket.actions.updateBasketProductCount();
            },
            openBasket: function () {
                basket.open = true;
                basket.actions.updateBasketProductItems();
                basket.doms.basket.addClass('active');
            },
            closeBasket: function () {
                basket.open = false;
                basket.doms.basket.removeClass('active');
            },
            closeBasketModal: function () {
                basket.doms.productModal.modal('hide');
            },
            toggleBasket: function () {
                if (basket.open) {
                    basket.actions.closeBasket();
                } else {
                    basket.actions.openBasket();
                    $('[data-hamburgers-btn], [data-hamburgers-menu]').toggleClass('is-active');
                }
            },
            updateBasketProductItems: function () {
                basket.doms.basketProductContainer.html('');

                basket.products.forEach(function (product, i) {
                    basket.doms.basketProductContainer.append('<div class="item-product">' +
                        '<div class="item-product-image" title="' + product.name + '" style="background-image: url(' + product.image + ');">' +
                        '<span class="item-product-piece" style="background-color: ' + product.color.code + ';">' + product.piece + '</span>' +
                        '<i class="fi fi-close" data-button="remove-product" data-index="' + i + '" data-from="basket"></i>' +
                        '</div>' +
                        '</div>');
                });
            },
            updateBasketProductCount: function () {
                basket.doms.basketProductCount.html(basket.products.length);
            },
            updateModalBasketProductItems: function () {
                basket.doms.productModalProductContainer.html('');

                basket.products.forEach(function (product, i) {
                    basket.doms.productModalProductContainer.append('<tr>' +
                        '<td>' +
                        '<i class="fi fi-close" data-button="remove-product" data-index="' + i + '" data-from="modal"></i> ' +
                        product.name +
                        '</td>' +
                        '<td style="white-space: nowrap; color: ' + product.color.code + '; text-shadow: 0 1px 2px #333; font-weight: bold;">' + product.color.label + '</td>' +
                        '<td class="text-center" style="white-space: nowrap;">' + product.piece + '</td>' +
                        '</tr>');
                });
            },
            openOfferModal: function () {
                basket.actions.updateModalBasketProductItems();
                basket.doms.productModal.modal('show');
            },
            sendForm: function () {
                if (basket.products.length === 0) {
                    alert('Sepette ürün bulunmuyor');
                    return null;
                }

                var form = $('form[name="product-form"]');

                $.ajax({
                    url: $('meta[name="app_url"]').attr('content') + '/forms/basket',
                    method: 'post',
                    data: {
                        full_name: $('input[name="full_name"]', form).val(),
                        phone_number: $('input[name="phone_number"]', form).val(),
                        message: $('input[name="message"]', form).val(),
                        products: Cookies.getJSON('basket')
                    },
                    dataType: 'json',
                    complete: function () {

                    },
                    success: function () {
                        form[0].reset();
                        basket.products = [];
                        basket.actions.updateBasketProductCount();
                        basket.actions.updateBasketProductItems();
                        basket.actions.updateModalBasketProductItems();
                        basket.actions.closeBasket();
                        basket.actions.closeBasketModal();
                        Cookies.set('basket', basket.products);
                        alert('Teklif İsteği Gönderildi. İlginiz için teşekkür ederiz.');
                    },
                    error: function () {
                        alert('Form Göderilemedi!');
                    }
                });
            }
        },
        events: {
            register: function () {
                var cookieBasketProducts = Cookies.getJSON('basket');

                if (typeof cookieBasketProducts === 'object') {
                    basket.products = cookieBasketProducts;
                }

                basket.actions.updateBasketProductCount();

                basket.doms.basketProductContainer.on('click', '[data-button="remove-product"]', function () {
                    var button = $(this);
                    var index = parseInt(button.attr('data-index'));
                    var from = button.attr('data-from');

                    basket.actions.removeProduct(index, from);
                });

                basket.doms.productModalProductContainer.on('click', '[data-button="remove-product"]', function () {
                    var button = $(this);
                    var index = parseInt(button.attr('data-index'));
                    var from = button.attr('data-from');

                    basket.actions.removeProduct(index, from);
                });
            }
        }
    };

    this.basketActions = {
        add: function (id, name, imageSrc) {
            basket.actions.addProduct(id, name, imageSrc);
        },
        open: function () {
            basket.actions.openBasket();
        },
        close: function () {
            basket.actions.closeBasket();
        },
        toggle: function () {
            basket.actions.toggleBasket();
        },
        update: function () {
            basket.actions.updateBasketProductCount();
        },
        send: function () {
            basket.actions.sendForm();
        },
        openOfferModal: function () {
            basket.actions.openOfferModal();
        }
    };

    this.sendContactForm = function () {
        var form = $('form[name="form-contact"]');

        $.ajax({
            url: $('meta[name="app_url"]').attr('content') + '/forms/contact',
            method: 'post',
            data: form.serialize(),
            dataType: 'json',
            complete: function () {

            },
            success: function () {
                form[0].reset();
                alert('Form Gönderildi. İlginiz için teşekkür ederiz.');
            },
            error: function () {
                alert('Form Göderilemedi!');
            }
        });
    };

    this.run = function () {
        $.ajaxSetup({
            beforeSend: function (xhr, settings) {
                xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf_token"]').attr('content'));
            }
        });
        plugins();
        homeDetail();
        productDetail();
        colorSelector();
        dropdownAutoCloseDisable();
        showCartBox();
        modify();
        homeCheckboxSelect();
        basket.events.register();
        searchPageForm();
        promoFormShow();
    };

    this.resize = function () {

    };

    this.load = function () {

    };

};

var ready = new Script();

$(function () {
    ready.run();
});
