var Slider = function () {

    var parent = $('section.box-group-promo');
    var btn = $('section.box-group-promo .promo-arrow');

    var init = function () {
        btn.on('click', function () {
            var i = parent.find('.promo-item.active').index();
            if ($(this).attr('data-direction') === 'next') {
                console.log(parent.find('.promo-item').length);
                console.log(i);
                if (i === (parent.find('.promo-item').length - 1)) {
                    i = 0;
                } else {
                    i = i + 1;
                }
            } else {
                if (i === 0) {
                    i = (parent.find('.promo-item').length) - 1;
                } else {
                    i = i - 1;
                }
            }

            console.log(i);
            active(i);
        });
    };

    var active = function (i) {
        parent.find('.promo-item').removeClass('active');
        parent.find('.promo-item').eq(i).addClass('active');
        parent.attr('data-bg', i);
        $('section.box-group-series').css({
            'transform': 'translate(0,0)'
        });
    };


    this.run = function () {
        init();
        active(0);
    };

};

var slider = new Slider();

$(function () {
    slider.run();
});
